export const labels = {
  0: 'Apple_Bad',
  1: 'Apple_Good',
  2: 'Banana_Bad',
  3: 'Banana_Good',
  4: 'Guava_Bad',
  5: 'Guava_Good',
  6: 'Lime_Bad',
  7: 'Lime_Good',
  8: 'Orange_Bad',
  9: 'Orange_Good',
  10: 'Pomegranate_Bad',
  11: 'Pomegranate_Good',
};
